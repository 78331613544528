import React from 'react';
import { BsFileEarmarkSpreadsheet, BsGear, BsGraphUp } from "react-icons/bs"
import { GiLaurelsTrophy } from "react-icons/gi"
import { RiMedalLine, RiMoneyDollarCircleLine } from "react-icons/ri"

const TransformSection = () =>    <section className="quick-base-glance" style={{ padding: "3rem 0" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="text-center mb-5">
                  Transform Your Business with Low Code
                </h2>
              </div>
              <div className="col-lg-4 col-md-4 mt-5 text-center">
                <BsGear />
                <h3 className="my-4">Rapid Application Development</h3>
                <p>Build superior, custom applications in days. Not months.</p>
              </div>
              <div className="col-lg-4 col-md-4 mt-5 text-center">
                <BsFileEarmarkSpreadsheet />
                <h3 className="my-4">Reduce the Need for Spreadsheets</h3>
                <p>
                  Get actionable insights at a glance with clear data
                  dashboarding.
                </p>
              </div>
              <div className="col-lg-4 col-md-4 mt-5 text-center">
                <RiMoneyDollarCircleLine />
                <h3 className="my-4">
                  Affordable
                  <br />
                  Custom Software
                </h3>
                <p>
                  Apps go live faster with fewer issues. Costs scale with use.
                </p>
              </div>
              <div className="col-lg-4 col-md-4 mt-5 text-center">
                <RiMedalLine />
                <h3 className="my-4">
                  Stay
                  <br />
                  Competitive
                </h3>
                <p>
                  Do more with less and stay agile in unpredictable markets.
                </p>
              </div>
              <div className="col-lg-4 col-md-4 mt-5 text-center">
                <BsGraphUp />
                <h3 className="my-4">
                  Increase
                  <br />
                  Productivity
                </h3>
                <p>
                  Connect workflows. Reduce waste. Eliminate manual tasks.
                  Exceed everyone's expectations.
                </p>
              </div>
              <div className="col-lg-4 col-md-4 mt-5 text-center">
                <GiLaurelsTrophy />
                <h3 className="my-4">
                  Transform Your
                  <br />
                  Business
                </h3>
                <p>
                  Build the features and solutions your customers have been
                  waiting for.
                </p>
              </div>
            </div>
          </div>
        </section>

export default TransformSection;
